<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="reviewTitleForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="工作单位"
              prop="workUnit"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.workUnit"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="姓名"
              prop="name"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.name"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="性别"
              prop="gender"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-select v-model="info.gender" placeholder="请选择">
                <a-select-option :value="1"> 男 </a-select-option>
                <a-select-option :value="2"> 女 </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="身份证号"
              prop="idCard"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input placeholder="请输入" v-model="info.idCard"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="毕业院校"
              prop="graduation"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model.number="info.graduation"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="证书编号"
              prop="certificateNumber"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model="info.certificateNumber"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="所学专业"
              prop="speciality"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input placeholder="请输入" v-model="info.speciality"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="学历"
              prop="education"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input placeholder="请输入" v-model="info.education"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="学历形式"
              prop="educationForm"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model="info.educationForm"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="学制"
              prop="schoolSystem"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model="info.schoolSystem"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="参加工作日期"
              prop="joinWorkDate"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model="info.joinWorkDate"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="现职称"
              prop="currentTitle"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-select v-model="info.currentTitle" placeholder="请选择">
                <template v-for="item in dictionaries.titleList">
                  <a-select-option :value="item.id" :key="item.id">
                    {{ item.itemName }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="职称获得日期"
              prop="titleGetDate"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model="info.titleGetDate"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="申报专业"
              prop="declareMajor"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-select v-model="info.declareMajor" placeholder="请选择">
                <template v-for="item in dictionaries.majorList">
                  <a-select-option :value="item.id" :key="item.id">
                    {{ item.itemName }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="申报资格"
              prop="declareQualification"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-select
                v-model="info.declareQualification"
                placeholder="请选择"
              >
                <template v-for="item in dictionaries.qualificationList">
                  <a-select-option :value="item.id" :key="item.id">
                    {{ item.itemName }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="档案部门"
              prop="archivesDepartment"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-select v-model="info.archivesDepartment" placeholder="请选择">
                <a-select-option
                  v-for="item in dictionaries.departmentList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.itemName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" v-if="info.archivesDepartment == 1258">
            <a-form-model-item
              label="存档机构名称"
              prop="otherArchivesDepartment"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                placeholder="请输入"
                v-model="info.otherArchivesDepartment"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-form-model-item
            label="代表作品名称"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 18 }"
            prop="representativeWork"
          >
            <a-textarea
              placeholder="请输入"
              v-model="info.representativeWork"
            ></a-textarea>
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item
            label="备注"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 18 }"
            prop="remark"
          >
            <a-textarea
              placeholder="请输入"
              v-model="info.remark"
            ></a-textarea>
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="附件"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-upload
                :action="$api.file.uploadFile"
                :fileList="fileList"
                :beforeUpload="beforeUpload"
                @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <a-button> <a-icon type="upload" /> 上传 </a-button>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider orientation="left"> 回复</a-divider>
        <a-row>
          <a-form-model-item
            label="回复"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 18 }"
            prop="replyResult"
          >
            <a-textarea
              placeholder="请输入"
              v-model="info.replyResult"
            ></a-textarea>
          </a-form-model-item>
        </a-row>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn
            v-if="infos.status == 1"
            name="回复"
            type="primary"
            @click="handleSubmit"
          />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  name: "RecruitPractice",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      info: {},
      rules: {
        replyResult: [
          {
            required: true,
            message: "请输入回复内容",
            trigger: "blur",
          },
        ],
      },
      dictionaries: {
        titleList: [],
        majorList: [],
        qualificationList: [],
        departmentList: [],
      },
      fileList: [],
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.id != 0) {
      _this.getInfo();
    }
    _this.getDictionaryItemList();
  },
  methods: {
    /**获取标签列表 */
    async getDictionaryItemList() {
      _this.$api.dictionaryItem.tree(47).then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.dictionaries.titleList = res.data;
        }
      });
      _this.$api.dictionaryItem.tree(48).then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.dictionaries.majorList = res.data;
        }
      });
      _this.$api.dictionaryItem.tree(49).then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.dictionaries.qualificationList = res.data;
        }
      });
      _this.$api.dictionaryItem.tree(50).then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.dictionaries.departmentList = res.data;
        }
      });
    },
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },

    //详情
    getInfo() {
      _this.isloading = true;
      _this.$api.reviewTitle
        .getReviewTitle(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
            if (res.data.attachList && res.data.attachList.length > 0) {
              res.data.attachList.forEach((item) => {
                _this.fileList.push({
                  uid: item.id,
                  name: item.fileName,
                  status: "done",
                  url: item.fileUrl,
                });
              });
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.reviewTitleForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let formData = {
            replyResult: _this.info.replyResult,
            status: 2,
          };

          if (_this.infos.id != 0) {
            formData.id = _this.infos.id;
          }
          _this.$api.reviewTitle
            .reply(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    beforeUpload(file) {
      const isLt1G = file.size / 1024 / 1024 < 1024;
      if (!isLt1G) {
        _this.$message.error("大小不能大于1G");
      }
      return isLt1G;
    },
    handleChange(info) {
      _this.fileList = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.fileList[_this.fileList.length - 1].url =
            info.file.response.data.path;
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
      }
    },
    onChange() {
      this.$forceUpdate();
    },
  },
};
</script>
<style>
</style>