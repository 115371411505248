<template>
  <div class="resourceContent">
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="5">
            <a-form-item label="姓名">
              <a-input placeholder="请输入" v-model="search.name" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="工作单位">
              <a-input placeholder="请输入" v-model="search.workUnit" />
            </a-form-item>
          </a-col>
          <a-col :span="3" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="附件模板" help="注：支持格式zip、rar，小于20M">
              <a-upload
                accept="application/zip, application/x-rar-compressed"
                :action="$api.file.uploadFile"
                :fileList="fileList"
                :beforeUpload="beforeUpload"
                @change="handleUploadChange"
              >
                <a-button type="primary" v-if="fileList.length == 0">
                  <a-icon type="upload" /> 上传模板
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="导出"
          :disabled="selectRows.length == 0"
          @click="exportDatas"
          :roles="[]"
        />
      </div>
    </div>
    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          onChange: onRowChange,
          columnWidth: 40,
          selectedRowKeys: selectRows,
        }"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="gender" slot-scope="gender">
          <p>{{ gender == 1 ? "男" : "女" }}</p>
        </span>
        <span slot="status" slot-scope="status">
          <a-tag v-if="status == 1" color="blue"> 未回复 </a-tag>
          <a-tag v-else color="green"> 已回复 </a-tag>
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            :name="action.status == 1 ? '回复' : '查看'"
            @click="showInfo(action.id, action.status)"
            size="small"
            type="primary"
            class="pxkeji_dro_btn"
            :roles="[]"
          />
          <a-popconfirm @confirm="delInfo(action.id)">
            <div slot="title">是否确认删除此职称评审记录？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              class="pxkeji_dro_btn"
              :roles="[]"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>
    <a-modal
      :title="info.title"
      v-model="info.visible"
      width="1000px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ReviewTitleInfo @callbackMethod="save" :infos="info" />
    </a-modal>
  </div>
</template>
<script>
import ReviewTitleInfo from "./reviewTitleInfo.vue"; //编辑

const columns = [
  {
    dataIndex: "name",
    align: "left",
    title: "姓名",
  },
  {
    dataIndex: "idCard",
    align: "center",
    title: "身份证号",
  },
  {
    dataIndex: "gender",
    align: "center",
    title: "性别",
    scopedSlots: { customRender: "gender" },
  },
  {
    dataIndex: "workUnit",
    key: "workUnit",
    align: "center",
    title: "工作单位",
  },
  {
    dataIndex: "currentTitleName",
    key: "currentTitleName",
    align: "center",
    title: "现职称",
  },
  //   {
  //     dataIndex: "graduation",
  //     align: "center",
  //     title: "毕业院校",
  //   },
  //   {
  //     dataIndex: "certificateNumber",
  //     align: "center",
  //     title: "证书编号",
  //   },
  //   {
  //     dataIndex: "speciality",
  //     align: "center",
  //     title: "所学专业",
  //   },
  //   {
  //     dataIndex: "education",
  //     align: "center",
  //     title: "学历",
  //   },
  //   {
  //     dataIndex: "educationForm",
  //     align: "center",
  //     title: "学历形式",
  //   },
  //   {
  //     dataIndex: "schoolSystem",
  //     align: "center",
  //     title: "学制",
  //   },

  //   {
  //     dataIndex: "declareMajor",
  //     align: "center",
  //     title: "申报专业",
  //   },
  //   {
  //     dataIndex: "declareQualification",
  //     align: "center",
  //     title: "申报资格",
  //   },
  {
    dataIndex: "status",
    key: "status",
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "ReviewTitleList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      search: {
        status: "",
        name: "",
        workUnit: "",
      },
      selectRows: [], //选中项编号
      isloading: false,
      info: {
        title: "",
        visible: false,
        status: "",
        id: 0,
      },
      selectCertRows: [],
      fileList: [],
      reviewTitleConfig: {
        enclosure: "",
      },
    };
  },
  components: {
    ReviewTitleInfo,
  },
  mounted() {
    _this = this;
    _this.getReviewTitleConfig();
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 293;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    getReviewTitleConfig() {
      _this.$api.reviewTitle.getReviewTitleConfig().then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          let fileInfo = JSON.parse(res.data.enclosure);
          _this.fileList.push({
            uid: 1,
            name: fileInfo.fileName,
            status: "done",
          });
        }
      });
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      _this.$api.reviewTitle
        .getReviewTitleList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.name,
          _this.search.workUnit
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id, status) {
      _this.info.title = status == 1 ? "回复" : "查看";
      _this.info.id = id;
      _this.info.status = status;
      _this.info.visible = true;
    },
    /**删除 */
    delInfo(ids) {
      _this.$api.reviewTitle
        .deleteReviewTitle(ids)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    exportDatas() {
      let data = {
        name: _this.search.name,
        workUnit: _this.search.workUnit,
      };
      if (_this.selectRows.length > 0) {
        data.ids = _this.selectRows.join();
      }
      window.open(_this.$api.reviewTitle.exportDatas(data));
    },
    //子组件传值
    save() {
      _this.info.visible = false;
      _this.GetList();
    },
    beforeUpload(file) {
      let isType = false;
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      let types = ["zip", "rar"];
      if (types.indexOf(fileType) != -1) {
        isType = true;
      } else {
        isType = false;
        _this.$message.error("只能上传指定的文件格式");
      }
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        _this.$message.error("文件不能超过20MB");
      }
      return isType && isLt20M;
    },
    handleUploadChange(fileInfo) {
      if (fileInfo.file.status) {
        let fileList = [...fileInfo.fileList];
        _this.fileList = fileList.slice(-1);
        if (fileInfo.file.status == "done") {
          if (
            fileInfo.file.response.errorCode != _this.$msg.responseCode.success
          ) {
            _this.$message.error("上传失败");
          } else {
            let configInfo = {
              fileName: fileInfo.file.response.data.name,
              filePath: fileInfo.file.response.data.path,
            };
            _this.reviewTitleConfig.enclosure = JSON.stringify(configInfo);
            _this.$api.reviewTitle
              .setReviewTitleConfig(_this.reviewTitleConfig)
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success("保存成功");
                } else {
                  _this.$message.error("保存失败");
                }
              });
          }
        } else if (fileInfo.file.status == "error") {
          _this.$message.error("上传失败");
        } else if (fileInfo.file.status == "removed") {
          _this.reviewTitleConfig.enclosure = "";
          _this.$api.reviewTitle
            .setReviewTitleConfig(_this.reviewTitleConfig)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success("移除成功");
              } else {
                _this.$message.error("移除失败");
              }
            });
        }
      }
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.resourceContent:after {
  content: "";
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.articleMeauleft {
  width: 20%;
  padding: 0 1%;
  min-height: 100px;
  background: #fff;
  float: left;
  margin-top: 10px;
}
</style>

